<template>
  <div>
    <div class="mb-4 d-flex justify-content-end">
      <button
        class="btn btn-primary d-flex"
        @click="showNewReportGroupModal = true"
      >
        <i class="material-icons-outlined">add</i>
        <span class="ml-2"> New Report Group</span>
      </button>
    </div>
    <div>
      <router-link to="/reports">
        <button class="m-2 btn-info btn btn-sm d-flex">
          <i class="material-icons-outlined">chevron_left</i> Back
        </button>
      </router-link>
      <!-- SU AN HIZLI GEÇİŞ MENÜLERİ KALDIRILDI -->
      <!-- <router-link
        :to="`/reports/${type.id}`"
        v-for="(type, i) in reports"
        :key="i"
      >
        <button
          :class="`btn btn-${
            type.id == $route.params.reportId ? 'info' : 'light'
          } mx-2`"
        >
          <span
            :style="type.isActive === 0 ? 'text-decoration:line-through' : ''"
            >{{ type.description }}</span
          >
        </button>
      </router-link> -->
    </div>
    <div class="row justify-content-end">
      <div class="col-12 col-md-4 col-lg-3 my-4">
        <v-select
          :searchable="false"
          label="description"
          :disabled="isLoadingReportList"
          class="style-chooser"
          :value="activeReport"
          @option:selected="SET_PUSH_REPORT_TYPE"
          :clearable="false"
          placeholder="Select Report"
          :options="reports"
        >
        </v-select>
      </div>
    </div>
    <div>
      <div class="card-title">{{activeReport.description}}</div>
      <cardTableHeader :headers="fields" />
      <card-table-body
        :allItemsCount="reportGroupList.length"
        :items="reportGroupList"
        :loading="loading"
        :page="1"
        :pages="1"
      >
        <div
          class="
            card
            row
            p-3
            d-flex
            flex-row
            justify-content-sm-end justify-content-xl-start
          "
          v-for="(item, i) in reportGroupList"
          :key="i"
        >
          <div class="col-2 col-lg-1">{{ item.order }}</div>
          <div class="col-7 col-lg-4 text-primary">
            <router-link
              :to="`/reports/${$route.params.reportId}/reportGroups/${item.groupId}`"
            >
              <div class="mx-2">{{ item.description }}</div>
            </router-link>
          </div>
          <div class="col-3 col-lg-2">
            <span
              :class="`btn btn-xs btn-block btn-${
                item.isActive === 1 ? 'primary' : 'danger'
              }`"
              >{{ item.isActive === 1 ? "On" : "Off" }}</span
            >
          </div>
          <div class="col-12 col-lg-5 mt-4 mt-lg-0">
            <router-link
              :to="`/reports/${$route.params.reportId}/reportGroups/${item.groupId}`"
              ><button class="btn btn-info mx-2 btn-xs">
                Questions
              </button></router-link
            >
            <button
              class="btn btn-warning mx-2 btn-xs"
              @click="
                editReportGroup = { ...item, index: i, oldOrder: item.order };
                showModal = true;
              "
            >
              <i style="font-size: 13px" class="material-icons-outlined mr-2"
                >border_color</i
              >Update
            </button>
          </div>
        </div>
      </card-table-body>
    </div>
    <modal
      okText="Save"
      title="New Report Group"
      @ok="SEND_NEW_REPORT_GROUP_TO_API"
      v-model="showNewReportGroupModal"
    >
      <div
        class="alert text-center"
        v-if="alert.status"
        :class="`alert-${this.alert.variant}`"
      >
        {{ alert.message }}
      </div>
      <div class="form-group">
        <input
          class="form-control"
          id="email"
          maxlength="50"
          v-model="newReportGroup.description"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Group Name"
        />
      </div>
      <div class="mt-4">
        <h6>Order of Report Group</h6>
        <button
          @click="newReportGroup.order = list.order"
          :class="`btn btn-${
            list.order === newReportGroup.order ? 'success' : 'light'
          } m-2`"
          v-for="(list, i) in reportGroupList"
          :key="i"
        >
          {{ list.order }}.
        </button>
        <button
          @click="newReportGroup.order = newReportGroupOrder"
          :class="`btn btn-${
            newReportGroupOrder === newReportGroup.order ? 'success' : 'light'
          } m-2`"
        >
          {{ newReportGroupOrder }}.
        </button>
      </div>
    </modal>
    <modal
      okText="Save Changes"
      @ok="SEND_UPDATE_REPORT_TO_API"
      title="Update Report Group"
      v-model="showModal"
    >
      <div
        class="alert text-center"
        v-if="alert.status"
        :class="`alert-${this.alert.variant}`"
      >
        {{ alert.message }}
      </div>
      <div class="form-group">
        <input
          class="form-control"
          id="email"
          maxlength="50"
          v-model="editReportGroup.description"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Group Name"
        />
      </div>
      <div class="mt-4">
        <strong>Situation: </strong>
        <button
          @click="editReportGroup.isActive = 1"
          class="btn btn-xs mx-2"
          :class="{
            'btn-secondary': editReportGroup.isActive === 0,
            'btn-primary': editReportGroup.isActive === 1,
          }"
        >
          Active
        </button>
        <button
          @click="editReportGroup.isActive = 0"
          class="btn btn-xs mx-2"
          :class="{
            'btn-secondary': editReportGroup.isActive === 0,
            'btn-danger': editReportGroup.isActive === 0,
          }"
        >
          Archived
        </button>
      </div>
      <div class="mt-4">
        <h6>Order of Report Group</h6>
        <button
          @click="editReportGroup.order = list.order"
          :class="`btn btn-${
            list.order === editReportGroup.oldOrder
              ? 'danger'
              : list.order === editReportGroup.order
              ? 'success'
              : 'light'
          } m-2`"
          v-for="(list, i) in reportGroupList"
          :key="i"
        >
          {{ list.order }}.
        </button>
      </div>
      <div
        class="mt-4"
        v-if="editReportGroup.oldOrder !== editReportGroup.order"
      >
        The question group will be moved from the
        <button class="btn btn-xs btn-danger m-2">
          {{ editReportGroup.oldOrder }}.
        </button>
        row to the
        <button class="btn btn-xs btn-success m-2">
          {{ editReportGroup.order }}.
        </button>
        row.
      </div>
    </modal>
  </div>
</template>

<script>
import cardTableHeader from "../ui/table/cardTableHeader.vue";
import cardTableBody from "../ui/table/cardTableBody.vue";
import GetReportGroups from "./getReportGroups";
import GetReportType from "../reportType/getReportType";
import Modal from "../ui/modal.vue";
export default {
  mixins: [GetReportGroups, GetReportType],
  data() {
    return {
      alert: {
        status: false,
        variant: "success",
        message: "",
      },
      showNewReportGroupModal: false,
      showModal: false,
      editReportGroup: {},
      fields: [
        { label: "Id", sort: false, col: 12, md: 6, lg: 1, xl: 1 },
        { label: "Name", sort: false, col: 12, md: 6, lg: 4, xl: 4 },
        { label: "Situation", sort: false, col: 12, md: 6, lg: 2, xl: 2 },
        { label: "Actions", sort: false, col: 12, md: 6, lg: 5, xl: 5 },
      ],
    };
  },
  components: {
    cardTableHeader,
    cardTableBody,
    Modal,
  },
  methods: {
    SET_PUSH_REPORT_TYPE(e) {
      this.$router.push(`/reports/${e.id}`);
    },
    async SEND_NEW_REPORT_GROUP_TO_API(e, loading) {
      this.alert.status = false;
      if (this.newReportGroup.description === "") return;
      loading();
      const response = await this.$api.post("QuestionGroups", {
        description: this.newReportGroup.description,
        reportId: Number(this.$route.params.reportId),
        isActive: 1,
        order: this.newReportGroup.order,
      });
      if (response.result === "OK" && response.message === "OK") {
        this.alert = {
          status: true,
          variant: "success",
          message: "Report Group saved successfully",
        };
        this.newReportGroup.description = "";
        this.GET_INITIAL_REPORT_GROUP_FROM_API();
        setTimeout(() => {
          this.showNewReportGroupModal = false;
          this.alert.status = false;
        }, 1000);
      } else {
        this.alert = {
          status: true,
          variant: "danger",
          message: "Something went wrong. Please Try Again",
        };
      }
      loading(false);
    },
    async SEND_UPDATE_REPORT_TO_API(e, loading) {
      this.alert.status = false;
      if (this.editReportGroup.description === "") return;
      loading();
      const response = await this.$api.put(`ReportGroups`, {
        reportId: Number(this.$route.params.reportId),
        description: this.editReportGroup.description,
        oldOrder: this.editReportGroup.oldOrder,
        order: this.editReportGroup.order,
        groupId: this.editReportGroup.groupId,
        reportGroupId: this.editReportGroup.reportGroupId,
        isActive: this.editReportGroup.isActive,
      });
      if (response.result === "OK" && response.message === "OK") {
        this.alert = {
          status: true,
          variant: "success",
          message: "Report Group saved successfully",
        };
        this.GET_INITIAL_REPORT_GROUP_FROM_API();
        setTimeout(() => {
          this.showModal = false;
          this.alert.status = false;
        }, 1000);
      } else {
        this.alert = {
          status: true,
          variant: "danger",
          message: "Something went wrong. Please Try Again",
        };
      }
      loading(false);
    },
  },
};
</script>

<style>
</style>