<template>
  <div>
    <ReportGroups :key="keyOfComponent" />
  </div>
</template>

<script>
import ReportGroups from "../../components/reportGroups/index.vue";
export default {
  data(){
    return {
      keyOfComponent: 1
    }
  },
  watch: {
    $route: {
      deep: true,
      handler(data) {
        this.keyOfComponent = data.params.reportId
      },
    },
  },
  components: {
    ReportGroups,
  },
};
</script>

<style>
</style>